.CardBlock {
  background-color: var(--contrasted);
  cursor: pointer;
  border: none;
  margin: none;
  &:focus {
    outline: 2px solid var(--alert);
  }
}

.CardBlock__active {
  position: relative;
  background-color: var(--primary);

  &:after {
    content: "";
    position: absolute;
    background: url("../../../assets/images/checked-icon.svg") no-repeat;
    fill: red;
    top: 11.5px;
    right: 6.5px;
    height: 20px;
    width: 20px;
  }

  svg {
    fill: var(--contrasted) !important;
  }

  span {
    color: var(--contrasted);
  }

  a {
    color: var(--contrasted);
  }

  input[type="button"] {
    opacity: 0 !important;
  }
}

.CardBlock__disabled {
  background: var(--disable);
  cursor: default;

  & > div {
    pointer-events: none;
  }

  &:hover {
    outline: none;
  }
  svg {
    fill: var(--contrasted) !important;
  }
  span {
    color: var(--contrasted);
  }
}
