.Quote_Layout {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__buttons {
    padding-bottom: 60px;
  }

  &__section_title {
    text-align: center;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-content: center;
    padding: 30px;
    h1 {
      font-size: 34px;
      line-height: 35px;
      font-weight: 500;
      color: var(--primary);
    }
    div {
      color: var(--disable-primary);
    }
    button {
      margin: 5px;
    }
  }

  &__header {
    height: 78px;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
  }
  &__center {
    min-height: calc(100vh - 78px - 120px);
    align-items: start;
    display: flex;
    flex-direction: row;
  }
  &__content {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  &__sidebar {
    width: 370px;
    position: sticky;
    top: 78px;
    min-height: calc(100vh - 78px);
    background-size: cover;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: end;
    padding-bottom: 30px;
    gap:30px;

    @media only screen and (max-width: 800px) {
      display: none;
    }
    &__AdviceCardPos {
      position: fixed;
      bottom: 100px;
    }
  }
  &__footer {

  }

  section {
    width: 100%;
    padding: 0px 30px 30px 30px;

    h1 {
        color: var(--primary);
        font-size: 34px;
        line-height: 35px;
        font-weight: 500;
      p {
        margin-bottom: 0;
        font-weight: normal;
        font-size: 20px;
        line-height: 22px;
      }
    }
  }

  &__section1 {
    background-color: rgba(198,190,188,0.10);

  }
  &__section2 {

  }

  &__quoteContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 14px;
    color: var(--primary);

    font-size: 20px;
    font-weight: normal;

    a {
      font-weight: bold;
      text-decoration: underline;
    }

    h1 {
      font-size: 24px;
      margin-bottom: 14px;
    }

    ul {
      li {
        list-style: "- " inside !important;
      }
    }

  }

}
