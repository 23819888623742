.NewSession {

    &__header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 50px;
    }

    &__session {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    &__session-card {
        margin-top: 20px;
    }

    &__btDemarrer {
        width: 100%;
        left: 35%;
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 80px;
        margin-bottom: 80px;
    }

    &__date {
        margin-top: 30px;
    }

    &__date-input,&__name-input {
        margin-top: 10px;
    }
}