
.AdviceCard {
  background-color: #fff;
  width: 300px;
  min-height: 300px;
  border-radius: 8px;
  color: var(--secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px 10px;
  padding: 10px;
  overflow: hidden;

  &__Image {
    width: 300px;
    height: 150px;
    background-position: center;
    background-size: cover;
    margin-top: -15px;
  }

  h2 {

    width: 100%;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    svg {
      margin-right: 5px;
    }
  }


  &__Body {
    width: 100%;

    img {
      vertical-align: middle;
      margin: 5px;
    }

    h1 {
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      font-style: italic;
    }

    p, h1, h2, h3 {
      margin: 10px 5px;
    }
    li p {
      margin: 0;
    }

    ul > li:before {
      content: " - ";
    }

    ul >li {
      margin:0 0 6px 6px; /* Give each list item a left margin to make room for the numbers */
      padding:4px 8px; /* Add some spacing around the content */
    }

    hr {
      opacity: 0.3;
      border-style: solid;
      border-width: 0.5px;
    }

    ol {
      counter-reset:li; /* Initiate a counter */
      margin-left:0; /* Remove the default left margin */
      padding-left:0; /* Remove the default left padding */
    }
    ol > li {
      position:relative; /* Create a positioning context */
      margin:0 0 6px 20px; /* Give each list item a left margin to make room for the numbers */
      padding:4px 8px; /* Add some spacing around the content */
      list-style:none; /* Disable the normal item numbering */
    }

    ol > li:before {
      content:counter(li); /* Use the counter as content */
      counter-increment:li; /* Increment the counter by 1 */
      /* Position and style the number */
      position:absolute;
      top:5px;
      left:-19px;
      -moz-box-sizing:border-box;
      -webkit-box-sizing:border-box;
      box-sizing:border-box;
      width:20px;
      height:20px;
      /* Some space between the number and the content in browsers that support
         generated content but not positioning it (Camino 2 is one example) */
      margin-right:8px;
      padding:4px;
      border:1px solid var(--secondary);
      border-radius: 12px;
      color:var(--secondary);
      background:#fff;
      font-weight:normal;
      font-family: var(--font);
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li ol,
    li ul {margin-top:6px;}
    ol ol li:last-child {margin-bottom:0;}
  }
}