

.ProductChoiceField {

  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__Title {
    font-family: var(--font-not-condensed);
    font-size: 13px;
    font-weight: bold;
    height: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__No {
    font-family: var(--font-not-condensed);
    font-size: 13px;
    font-weight: bold;
    height: 45px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__Price {
    font-family: var(--font-not-condensed);
    font-size: 22px;
    font-weight: 400;
    height: 45px;

    span {
      font-family: var(--font);
      font-size: 13px;
      font-weight: 500;
      color: var(--secondary);
      text-transform: lowercase;
    }
  }

  &__Description {
    font-size: 14px;
    font-weight: normal;
    text-transform: none;
  }

  &__Link {
    font-size: 14px;
    font-weight: normal;
    text-decoration: underline;
    text-transform: none;
    cursor: pointer;
  }

  &__ModalBody {
    min-height: 170px;

  }

  &__Modal {
    color: var(--primary);
    font-weight: normal;
    font-size: 18px;
    h2 {
      font-weight: 400;
      font-size: 24px;
    }
  }

  Button {
    position: absolute;
    bottom: 0;
  }
}
.ProductChoiceField:has(button) {
  cursor: default;

}
