.BatchSubscriptionTabs {
  width: 100%;
  margin-bottom: 20%;
  margin-top: 5%;

  &__addBatch {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
   &__addBatch-button {
     width: 100%;
     display: contents;
   }
}
