@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800&display=swap");

:root {
  --primary: #024e7d;
  --secondary: #8e7e7a;
  --alert: #0ddfb5;
  --error: #ff8283;
  --contrasted: #ffffff;
  --disable: #c6bebc;
  --background: #fafafa;
  --disable-background-primary: #F3F3F3;
  --disable-primary: #80A6BE;
  --font: "Barlow Condensed", sans-serif;
  --font-not-condensed: "Barlow", sans-serif;

  --border: 0;
  --padding-vertical: px;
  --portrait-width: 830px;
  --paysage-width: 1200px;
}

/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) {
  :root {
    --portrait-width: 748px;
    --paysage-width: 758px;
  }
}

/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) {
  :root {
    --portrait-width: 830px;
    --paysage-width: 879px;
  }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1180px) {
  :root {
    --portrait-width: 830px;
    --paysage-width: 1100px;
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: var(--font);
  color: unset;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  outline: none;
}

body {
  background-color: var(--background);
}

.Page {
  max-width: calc(min(100vw - 20px, var(--portrait-width)));
  margin: auto;
}

.PagePaysage {
  max-width: calc(min(100vw - 20px, var(--paysage-width)));
  margin: auto;
}

.PageQuote {
  max-width: calc(min(100vw - 250px, var(--paysage-width)));
  margin: auto;
  aside {
    img {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
}

/*.Page {
  max-width: var(--paysage-width);
}*/

/**
 * LoadingContext indeterminateAnimation
 */
.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

.MuiTabs-root {
  min-height: 24px !important;
}

.MuiTab-root {
  text-transform: initial !important;
  min-height: 24px !important;
  padding: 4px 8px !important;
}

.MuiTabs-indicator {
  background-color: #0ddfb5 !important;
}

.MuiButtonBase-root.MuiButton-contained,
.MuiButtonBase-root.MuiButton-outlined{
  //text-transform: initial !important;
  border-radius: 20px !important;
  box-shadow: none !important;
  // min-width: 140px !important;
}

.MuiToggleButton-root {
  outline: 0 !important;
  border: 1px solid white !important;
}

.MuiInputBase-input {
  background-color: #ffffff !important;
}

.MuiAutocomplete {
  background-color: #ffffff !important;
}

.MuiOutlinedInput-root {
  background-color: #ffffff !important;
}
