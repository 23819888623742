

.ProductChoiceList {

  &__Card {
    height: 142px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    Button {
      position: absolute;
      bottom: 0;
    }
  }

  &__Title {
    font-family: var(--font-not-condensed);
    font-size: 13px;
    font-weight: bold;
    height: 40px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__Price {
    font-family: var(--font-not-condensed);
    font-size: 22px;
    font-weight: 400;
    height: 30px;
    position: relative;

    .ProductChoiceList__StartingPrice {
      position: absolute;
      top: -10px;
      width: 100%;
      font-size: 10px;
      font-weight: 500;
      font-style: italic;
      text-transform: lowercase;
    }

    span {
      font-family: var(--font);
      font-size: 13px;
      font-weight: 500;
      color: var(--secondary);
      text-transform: lowercase;
    }
  }

  &__Link {
    font-size: 14px;
    font-weight: normal;
    text-decoration: underline;
    text-transform: none;
    cursor: pointer;
  }

  &__ModalBody {
    min-height: 170px;

  }

  &__Modal {
    color: var(--primary);
    font-weight: normal;
    font-size: 18px;
    h2 {
      font-weight: 400;
      font-size: 24px;
    }
  }


}