
.FleetItem{

  width: 210px;
  position: relative;
  overflow: hidden;

  &__Title {
    font-size: 16px;
    font-weight: normal;
  }

  &__Image {
    max-height: 30px !important;
  }

  &__Price {
    text-transform: lowercase;
    color: var(--error);
    font-size: 14px;
    text-decoration: line-through;
    font-weight: 400;
    font-face: var(--font-not-condensed);
    span {
      font-face: var(--font);
      font-size: 12px;
    }
  }
  &__PriceDiscounted {
    text-transform: lowercase;
    font-size: 30px;
    font-weight: 400;
    span {
      font-size: 16px;
      color: var(--secondary);
    }
  }

  &__Option {
    font-size: 16px;
    font-weight: 500;
  }

  &__Ruban {
    font-size: 14px;
    background-color: var(--error);
    color:var(--contrasted);
    position: absolute;
    padding: 2px 40px;
    font-weight: 600;
    top:10px;
    left: -30px;
    rotate: -45deg;
  }

  &__Add {
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    font-face: var(--font-not-condensed);
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}