.CardBlockTextTag {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 180px;
  height: min-content;
  padding: 8.5px 15.5px;
  border: 1px solid rgba(2, 78, 125, 0.15);
  margin-right: 10px;
  margin-bottom: 10px;
  &__text {
    font-weight: 600;
    font-size: 16px;
    color: var(--primary);
    margin-bottom: 5px;
  }
}
