
.Numberplate{
  display: flex;
  align-items: start;
  flex-direction: row;

  &__Logo {
    background-color: var(--primary);
    width: 39px;
    border-radius: 5px 0 0 5px;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 1;
    height: 56px;
  }

  .MuiInputBase-root {
    margin-left: -10px;
  }

  .MuiButtonBase-root.MuiButton-contained {
    margin-left: 10px;
    border-radius: 4px !important;
    min-width: 150px !important;
    height: 56px;
  }


}