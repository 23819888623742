.Rater_Layout {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__header {
    height: 78px;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
  }
  &__center {
    min-height: calc(100vh - 78px - 120px);
    align-items: start;
    display: flex;
    flex-direction: row;
  }
  &__content {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 40px;
  }
  &__sidebar {
    width: 370px;
    position: sticky;
    top: 78px;
    min-height: calc(100vh - 78px);
    background-size: cover;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: end;
    padding-bottom: 30px;
    gap:30px;

    @media only screen and (max-width: 800px) {
      display: none;
    }
    &__AdviceCardPos {
      position: fixed;
      bottom: 100px;
    }
  }
  &__footer {

  }
}
