.FoundedCarItem {
  width: 468px;
  position: relative;
  overflow: hidden;

  &__Title1 {
    font-size: 16px;
    font-weight: bold;
  }

  &__Title2 {
    font-size: 16px;
    font-weight: normal;
  }

  &__Image {
    max-height: 30px !important;
  }

  &__Option {
    font-size: 16px;
    font-weight: 500;
  }
}
