.routes-test {
  border: 1px solid;
  margin: 30px;
  a {
    display: block;
    text-decoration: underline;
  }
  legend {
    background-color: #000;
    color: #fff;
    padding: 3px 6px;
    margin-left: 5px;
  }
}

.Navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //width: 100vw;
  background-color: var(--contrasted);
  height: 78px;
  padding: 0px 30px;
  margin-bottom: 30px;
  &__help-button {
    display: flex;
    align-items: center;
    gap: 20px;
    span {
      color: var(--primary);
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
    }
  }
}
