

.AssetValueCard {
  position: relative;
  text-align: center;
  padding: 15px;
  width: 176px;
  height: 176px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);

  background-color: #ffffff;
  color: var(--primary);

  &__Title {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    font-face: var(--font-not-condensed);
    max-width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__Subtitle {
    font-size: 14px;
    font-weight: 0;
    max-width: 145px;
  }

  &__Input {
    position: absolute;
    font-size: 14px;
    width: 144px;
    bottom: 20px;
    height: 41px;
    padding: 12px 8px;
    border: 1px solid #C6BEBC;
    border-radius: 5px;
    text-align: center;
  }

}

.AssetValueCard:has(input:focus):after {
  position: absolute;
  bottom: 32px;
  margin: auto;
  content: "€";
  right: 22px;
}

.AssetValueStack {

  padding: 10px;
  gap: 75px;

  .AssetValueCard + .AssetValueCard::before {
    content: "OU";
    color: var(--primary);
    font-size: 24px;
    position: absolute;
    top: 68px;
    left: -50px;
  }
}

.AssetValueCard.disabled {
  background-color:  var(--disable-background-primary);
  color: var(--disable-primary);

  .AssetValueCard__Input {
    border-color: var(--disable);
    background-color: var(--disable-background-primary);
    color: var(--disable);
  }
}