
.BasketSummary {
  margin-bottom: 30px;

  h1 {
    color: var(--primary);
    font-size: 34px;
    line-height: 35px;
    font-weight: 500;
    p {
      margin-bottom: 0;
      font-weight: normal;
      font-size: 20px;
      line-height: 22px;
    }
    margin-bottom: 20px;
  }

  &__card {
    min-width: 360px;
    min-height: 360px;

    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    border: 2px solid #ffffff;
    color: var(--primary);
    font-family: var(--font-not-condensed);
    gap: 5px;
    padding: 15px;

  }

  &__picto {
    max-height: 38px;
    max-width: 100px;
    filter: invert(23%) sepia(64%) saturate(1381%) hue-rotate(175deg) brightness(90%) contrast(100%);
    margin-bottom: 10px;
  }

  &__title {
    font-weight: 500;
    font-size: 21px;
    text-transform: uppercase;
  }

  &__price {
    display: flex;
    flex-direction: column;
    align-items: center;

    .firstRow {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .euroDetail {
      display: flex;
      flex-direction: column;
      justify-content: left;
    }

    .euro{
      font-size: 74px;
      font-weight: bold;
      padding-right: 5px;
    }

    .centimes {
      font-size: 28px;
      font-weight: 400;
    }
    .ttc{
      font-size: 14px;
      font-weight: 400;
    }
    .period{
      font-size: 25px;
      font-weight: 400;
      color: var(--secondary);
    }
    .subprice{
      font-size: 16px;
      font-weight: 400;
      color: var(--secondary);
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 14px;
    color: var(--primary);

    font-size: 18px !important;
    font-weight: normal !important;

    a {
      font-weight: bold;
      text-decoration: underline;
    }

    h1 {
      font-size: 24px;
      margin-bottom: 14px;
    }

    ul {
      li {
        list-style: "- " inside !important;
      }
    }

  }
}