.Subscription {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;

  &__duplicates-title {
    display: block;
    color: var(--primary);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: 10px 0;
  }

  &__inputField-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 20px;
    margin-left: 15px;
    width: 100%;
  }

  &__documentBlock-container {
    display: flex;
    flex-direction: column;
    width: calc(50% - 20px);
    margin-left: 20px;
  }

  &__documentTitle-container {
    display: flex;
  }

  &__title {
    display: block;
    width: max-content;
    color: var(--primary);
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 20px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    cursor: pointer;
  }
  
  &__button {
    width: 100%;
    left: 35%;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__btPrecedent {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  &__menu-block{
    display: flex;
  }

  &-ResultList {
    margin-top: 10px;
  }

  &-ResultCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 525px;
    min-height: 60px;
    width: 100%;
    border: 1px solid rgba(2, 78, 125, 0.15);
    color: var(--primary);
    background-color: #ffffff;
    margin-top: -1px;
    padding: 10px;
  }

  &-ResultCard-selected {
    color: white;
    background-color: rgba(2, 78, 125, 1) !important;
  }

  &-ResultCard__name {
    font-weight: 500;
  }

  &-ResultCard__name {
    display: block;
  }
}
