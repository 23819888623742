
.SubscriptionStep {

  &__Card_done {
    //background-color: #D5F8F1;
    background-color: color-mix(in srgb,var(--alert),#fff 80%) !important;
  }
  &__Card_error {
    background-color: color-mix(in srgb,var(--error),#fff 80%) !important;;
  }
  &__Card_wait {
    background-color: color-mix(in srgb,var(--secondary),#fff 80%) !important;;
  }

  &__Card {
    width: inherit;
  }

}