.Advisor {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__advisor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 140px;
  }

  &__img {
    width: 80px;
    border-radius: 50px;
    margin-bottom: 8px;
  }

  &__span {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    width: 100px;
  }
  &__name {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: var(--primary);
  }

  &__description {
    font-style: italic;
    font-size: 10px;
    line-height: 10px;
    width: 120px;
    color: var(--secondary);
  }

  &__text {
    display: flex;
    align-items: center;

    position: relative;

    width: 650px;
    height: 100px;
    padding-left: 20px;

    border-radius: 8px;

    font-size: 16px;
    line-height: 19px;
    color: var(--primary);
    background-color: var(--contrasted);

    @media screen and (max-width: 600px) {
      max-width: 400px;
      padding: 10px;
    }
  }
}
