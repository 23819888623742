
.FleetMatrix {
  margin-bottom: 30px;

  h2 {
    color: var(--primary);
    font-size: 30px;
    line-height: 30px;
    font-weight: 500;
    p {
      margin-bottom: 0;
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
    }
    margin-bottom: 20px;
  }

  &__grid {
    display: grid;
    row-gap: 30px;
    column-gap: 10px;
  }

  &__Option {

  }
  &__Header1 {
    color: var(--contrasted);
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 83px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);


    .garanties {
      font-size: 12px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__Header2 {
    color: var(--contrasted);
    background-color: var(--primary);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 83px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);

  }
  &__Item {
    background-color: var(--contrasted);
    display: flex;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: start;
    justify-content: center;
    gap: 5px;
    color: var(--primary);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);

  }
  &__ItemOption {
    background-color: var(--contrasted);
    flex-direction: column;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    font-size: 21px;
    font-weight: 500;
    font-family: var(--font-not-condensed);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);

  }
  &__ItemOption.selected {
    background-color: var(--alert);
    color: var(--contrasted);
  }

  &__Options {
    display: flex;
    flex-direction: column;
  }
  &__ItemImage {
    max-height: 20px !important;
    filter: invert(23%) sepia(64%) saturate(1381%) hue-rotate(175deg) brightness(90%) contrast(100%);
  }
  &__ItemTitle {
    color: var(--primary);
    font-size: 16px;
    font-weight: 500;
  }
  &__ItemPrice {
    color: var(--secondary);
  }
  &__ItemPriceDiscounted {
    color: var(--error);
    text-decoration: line-through;
  }

  &__Options {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  }

  &__SecOption {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    background-color: var(--contrasted);
    border: solid 1px var(--contrasted);
    .title {
      color: var(--primary);
      font: var(--font-not-condensed);
      display: flex;
      flex-direction: column;
      width: 100%;
      a {
        font: var(--font-not-condensed);
        color: var(--primary);
        font-size:12px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .price {
      width: 50px;
      color: var(--secondary);
    }
  }
  &__SecOption.selected {
    background-color: var(--alert);
    .title {
      color: var(--contrasted);
      a {
        color: var(--contrasted);
      }
    }
    .price {
      color: var(--contrasted);
    }
  }




  &__ModalBody {
    min-height: 170px;

  }

  &__Modal {
    color: var(--primary);
    font-weight: normal;
    font-size: 18px;
    h2 {
      font-weight: 400;
      font-size: 24px;
    }
  }

}