
.HumanAdvice {

  &__Bubble {
    background: #fff;
    border-radius: 8px;
    width: 600px;
    height: 100px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--primary);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    max-width: 800px;
    position: relative;
    margin-left: 10px;

    a {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  &__Bubble-triangle {
    position: absolute;
    transform: translate(-50%, -50%) rotate(180deg);
    z-index: 1;
    top: 50%;
    left: -7px;
    border-top: 15px solid var(--contrasted);
    border-right: 15px solid var(--background);
  }


}