.DocumentBlock__title {
  display: block;
  width: max-content;
  color: var(--primary);
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 20px;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
  cursor: pointer;
}

.DocumentBlock {
  height:585px;  
  width:100%;
  place-items:center;  
  display:flex;
  
  background: rgba(2, 78, 125, 0.2);
  border-radius: 2.5px;

  overflow-y: auto;
  &__pdf-document {
    min-width: 380px;
    height: 100%;
    text-align: center;
  }
  &__pdf-page {
    margin: 10px 0;
    box-shadow: 0 0 5px 0 #ccc;
  }

  .react-transform-wrapper {
    display: flex;
    align-items: baseline;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }

}
