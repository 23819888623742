
.CardField:hover {
  border-color: var(--primary);
}

.CardField {
  border: 2px solid #ffffff;
  color: var(--primary);
  background-color: #ffffff;
  font-family: var(--font-not-condensed);
  width: 176px;
  min-height: 77px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
  padding: 15px;
}



.CardField:has(img) {
  min-height: 176px;
  font-size: 12px;
  justify-content: center;

  img {
    max-width: 90px;
    max-height: 90px;
    margin: 15px;
    filter: invert(23%) sepia(64%) saturate(1381%) hue-rotate(175deg) brightness(90%) contrast(100%);
  }
}


.CardField.selected {
  border: 2px solid var(--primary);
  color: #ffffff;
  background-color: var(--primary);
}
.CardField.selected:has(img) {

  img {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(121deg) brightness(104%) contrast(101%);
  }
}

.CardField.disabled {
  border: 2px solid var(--disable);
  color: #ffffff;
  background-color: var(--disable);
}
.CardField.disabled:has(img) {
  img {
    filter: invert(97%) sepia(0%) saturate(4378%) hue-rotate(208deg) brightness(182%) contrast(85%);
  }
}