.Header {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  width: 100%;
  background-color: var(--contrasted);
  height: 78px;
  padding: 0 30px !important;


  &__contact {
    font-size: 16px;
    font-family: var(--font);
    color: var(--primary);
  }

  &__fill {
    flex:1;
  }

  &__login {
    color: var(--primary);
  }
}
