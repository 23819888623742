.BatchSubscription {
  width: 100%;
  padding-top: 20px;

  &__addSouscripteur {
    display: flex;
    align-items: center;
  }

  &__addSouscripteur-Ajouter {
    margin-left: 30px;
  }

  &__search {
   margin-bottom: 10px;
  }

  &__addBatch {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
   &__addBatch-button {
     width: 100%;
     display: contents;
   }

  .MuiBox-root {
    background-color: white;
  }
}
