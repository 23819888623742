.TagBlock {
  border-radius: 4px;
  margin-top: 2px;
  white-space: nowrap;

  span {
    border-radius: 4px;
    color: var(--contrasted);
    padding: 3px 10px;
    line-height: 24px;
  }
  img {
    margin-right: 5px;
    margin-bottom: 1px;
  }
  &__success {
    color: #15c15d !important;
    background-color: #e7f9ef;
  }
  &__dark{
    // color: var(--error) !important;
    background-color: #333333;
  }
  &__toComplete {
    background: #fff3f3;
    color: var(--error) !important;
  }
  &__check {
    background-color: var(--alert);
  }
  &__filled {
    background-color: #cfdde6;
  }
  &__downloading {
    background-color: var(--primary);
  }
  &__undefined {
    background-color: var(--alert);
  }
}
