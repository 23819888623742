.SummaryBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 120px;
  width: 100%;
  border-bottom: 1px solid #b9cddc;
  color: var(--primary);
  &__container {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: white;
  }
  & > div {
    width: 25%;
  }
  &__status {
    max-width: 144px;
  }
  &__docPic {
    width: 40% !important;
    text-align: center;
    .react-pdf__Document {
      display: flex;
    }
    img {
      width: 100%;
      transform: scale(0.75);
    }
  }
}
