
.QuoteForm {

  max-width: 780px;
  gap: 50px;
  position: relative;

  &__BackButton {
    font-weight: normal !important;
  }
}

