.CardBlockTextIcon {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  background-color: var(--primary);
  height: 128px;
  width: 128px;
  margin-right: 21px;
  padding-top: 28px;
  &__icon {
    display: flex;
    justify-content: center;
    overflow: hidden;
    svg {
      fill: var(--primary);
      margin-bottom: 28px;
    }
  }
  &__text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    margin-bottom: 18px;

    font-weight: 600;

    text-transform: uppercase;
    text-align: center;

    color: var(--contrasted);
  }
}
// .CardBlockTextIcon__disabled {
//   background: var(--disable);
//   cursor: default;

//   & > div {
//     pointer-events: none;
//   }

//   &:hover {
//     outline: none;
//   }
//   svg {
//     fill: var(--contrasted);
//   }

//   h3 {
//     color: var(--contrasted);
//   }
// }
