.TitleBlock {
    
  &__container {
    width: 670px;
    color: var(--primary);
  }

  &__title {
    margin-bottom: 0;
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
  }

  &__text {
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 19px;
  }
}
