
.LongLabel {

  font-weight: 500;
  font-size: 18px;
  color: var(--primary);

  &__Error {
    color: var(--error);
  }

}