.CaptureDocument {
  &__Frame {
    overflow-y: auto;
    width: calc((100vh - 340px) / 1.34);
    height: calc(100vh - 340px);
    min-height: 600px;
    min-width: calc(600px / 1.34);
    background-color: #333333;
    display: flex;
    justify-content: center;
    color: white;
  }

  .react_pdf__Document {
    margin: 20px;
  }

  .react-pdf__Page {
    margin: 20px;
    border: 1px #c6bebc;
    box-shadow: 0 0 5px 0 #ccc;
  }
}
