.Login {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;

    &__Block {
        width: 50%;
        height: 100%;
        float: left;
        background: rgba(25, 59, 103, 0.05);

        @media only screen and (max-width: 768px) {
            width: 100%;
            height: 60%;
        }

        &__Logo {
            padding-top: 250px;
            max-width: 180px;
            height: 300px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: stretch;

            @media only screen and (max-width: 768px) {
                padding-top: 60px;
                max-width: 0px;
                height: 100px;
                margin: auto;
            }
        }
    }

    &__Image {
        width: 50%;
        height: 100%;
        background: url("../../assets/images/loginImage.png");
        background-size: cover;
        display: flex;

        @media only screen and (max-width: 768px) {
            width: 100%;
            height: 40%;
        }
    }

    &__Form {
        max-width: 500px;
        height: 300px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        background: white;
        padding: 50px;
        margin-top: 40px;

        @media only screen and (max-width: 768px) {
            max-width: 600px;
        }

        &__User__Input {
            margin-top: 10px;
        }

        &__Password {
            margin-top: 15px;
            margin-bottom: 10px;

            &__Input {
                margin-top: 10px;
            }
        }

        &__Button {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}