.ImageCapture {
  &__container {
    width: calc((100vh - 307px) / 1.34);
    height: calc(100vh - 394px);
    min-height: 600px;
    min-width: calc(600px / 1.34);
    touch-action: none;
    background-color: black;
    position: relative;
    overflow: hidden;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      z-index: 10000;
    }

    .overlay-toolbar {
      position: absolute;
      bottom: 15px;
      > * {
        z-index: 10000;
      }

      &.bottom-2nd {
        bottom: 65px;
      }

      button {
        margin: 0 5px;
        width: 125px;
        display: inline-block;
        text-align: center;
      }
    }

    .camera-component {
      position: relative;
      width: 100%;
      height: 100%;

      .TitleDescBlock__title {
        text-shadow: -2px 0 5px rgba(255, 255, 255, 0.7),
          0 2px 5px rgba(255, 255, 255, 0.7), 2px 0 5px rgba(255, 255, 255, 0.7),
          0 -2px 5px rgba(255, 255, 255, 0.7);
      }

      .photo-container,
      .video-container {
        width: 100%;
        height: 100%;
      }

      .video-container-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.2;
        z-index: 9998;
      }
      .video-container {
        overflow: hidden;
        overscroll-behavior: contain;
        z-index: 9999;
      }

      .camera-toolbar {
        position: absolute;
        bottom: 15px;
        > * {
          z-index: 9999;
        }

        &.top {
          bottom: unset;
          top: 15px;
        }

        button {
          margin: 0 5px;
        }
      }
    }
  }
}
